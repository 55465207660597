
















































import { Component, Prop, Vue } from "vue-property-decorator";
import { Application, IconsType, User } from "@/types";
import { mdiAccountOutline, mdiCalendarClock } from "@mdi/js";
import { namespace } from "vuex-class";
const AppModule = namespace("AppModule");
const ApplicationModule = namespace("ApplicationModule");
@Component({
  components: {
    LabelZip: () => import("@/components/Label/LabelZip.vue"),
    LabelStatus: () => import("@/components/Label/LabelStatus.vue"),
    CardHistory: () => import("@/components/Card/CardHistory.vue"),
    DocumentViewer: () => import("@/components/DocumentViewer.vue"),
    MenuEmployee: () => import("@/components/Menu/MenuEmployee.vue"),
    IconSubtitle: () => import("@/components/IconSubtitle.vue"),
    DialogDocumentViewer: () =>
      import("@/components/Menu/DialogDocumentViewer.vue")
  }
})
export default class VApplication extends Vue {
  //
  // PROPS
  @Prop() readonly uid!: string;
  //
  // COMPUTED
  @ApplicationModule.Getter
  application!: Application;
  @AppModule.Getter
  smallScreen!: boolean;
  @AppModule.Getter
  public contentHeight!: number;
  get isDocuments() {
    return this.application?.documents?.length && !this.smallScreen;
  }
  get overflowStyle() {
    return this.smallScreen
      ? null
      : {
          overflowY: "auto",
          height:
            (this.contentHeight < this.minHeight
              ? this.minHeight
              : this.contentHeight) + "px"
        };
  }
  //
  // DATA
  private minHeight = 650;
  private loading = false;
  private icons: IconsType = {
    account: mdiAccountOutline,
    time: mdiCalendarClock
  };
  //
  // METHODS
  toLocaleTime(time: string) {
    return new Date(time).toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    });
  }
  name(user: User) {
    let result = "";
    if (user.familyName) result = user.familyName + " ";
    if (user.firstName) result += user.firstName;
    return result;
  }
  //
  // LIFECYCLE HOOKS
  created() {
    this.loading = true;
    setTimeout(async () => {
      const res = await this.$store.dispatch(
        "ApplicationModule/fetchApplication",
        this.uid
      );
      if (!res) await this.$router.replace({ name: "404" });
      document.title = this.application.number;
      this.loading = false;
    }, 0);
  }
}
